import product from '@/shared/datasets/products';

// TODO remove the need for this
export const shopifyConsoleBagData = ({
  pkg,
  upsellItems = null,
  warrantyItem = null,
}) => {
  /*
    Order matters in Storyblok for the upsell Items since we are indexing the array
    Shopify variant names matter as well since we are using them to find the correct variant here
  */
  const lightGloves = upsellItems && upsellItems[0] && upsellItems[0].variants
    ? upsellItems[0].variants.find((v) => v?.name?.toLowerCase()?.includes('12oz'))
    : null;
  const heavyGloves = upsellItems && upsellItems[0] && upsellItems[0].variants
    ? upsellItems[0].variants.find((v) => v?.name?.toLowerCase()?.includes('16oz'))
    : null;
  const kidGloves = upsellItems && upsellItems[1] && upsellItems[1].variants
    ? upsellItems[1].variants[0]
    : null;
  const trackers = upsellItems && upsellItems[2] ? upsellItems[2] : null;

  const description = 'Our starter setup with the best free-standing bag on the market. Track your progress, compete against others, and access our complete library of on-demand workouts.';

  return {
    title: pkg?.title,
    id: pkg?.id,
    text: 'Our Starter Package + FightCamp Bag',
    description: pkg?.description ? pkg?.description : description,
    product: {
      price: pkg?.variants[0]?.price,
      affirmPrice: pkg?.variants[0]?.price,
      oldPrice: pkg?.variants[0]?.compareAtPrice,
      months: 24,
      monthly: Math.floor(pkg?.variants[0]?.price / 24),
      path: product.fightcampConsoleBag.path,
      internalPath: product.fightcampConsoleBag.internalPath,
    },
    category: 'package',
    coverImage: '/v4/fc-fightcamp-base-002.png',
    coverImageAlt: 'Fightcamp quick wraps and trackers',
    secondaryImage: '/v4/fc-fightcamp-base-man-shadowboxing-001.jpeg',
    secondaryImageAlt: 'Man with fightcamp base shadowboxing',
    includes: {
      basePackage: [
        {
          name: 'Console + 2 Trackers',
          image: {
            src: 'https://a.storyblok.com/f/152150/270x246/203950421d/fightcamp-console-2-trackers.jpg',
            alt: 'FightCamp Console with 2 trackers',
          },
        },
        {
          name: 'Quick Wraps',
          image: {
            src: 'v4/fc-product-includes-wraps-001.png',
            alt: 'FightCamp Quick Wraps',
          },
        },
        {
          name: 'Boxing Bag + Bag Ring',
          image: {
            src: 'https://a.storyblok.com/f/152150/247x246/f60105a1a6/small-bag-ring.jpg',
            alt: 'FightCamp Bag and Bag Ring',
          },
        },
      ],
      extras: [],
    },
    upsell: upsellItems ? [
      {
        name: upsellItems ? upsellItems[0]?.title : null,
        description: upsellItems ? upsellItems[0]?.description : null,
        image: '/v4/fc-additional-items-gloves-001.png',
        imageAlt: 'FightCamp Boxing Gloves',
        productVariants: [
          {
            name: 'Light Gloves (12 oz)',
            itemId: lightGloves?.id,
            description: 'Best for low-to-mid power strikes.',
            product: {
              id: lightGloves?.id,
              price: lightGloves?.price,
              category: 'accessory',
            },
          },
          {
            name: 'Heavy Gloves (16 oz)',
            itemId: heavyGloves?.id,
            description: 'More padding & weight for heavy hitters.',
            product: {
              id: heavyGloves?.id,
              price: heavyGloves?.price,
              category: 'accessory',
            },
          },
          {
            name: 'Kids Gloves (6 oz)',
            itemId: kidGloves?.id,
            description: 'Get the kids involved.',
            product: {
              id: kidGloves?.id,
              price: kidGloves?.price,
              category: 'accessory',
            },
          },
        ],
      },
      {
        name: trackers?.title,
        additionalInformation: 'Workout with a partner simultaneously. Your Console can support up to 4 Trackers.',
        image: 'https://a.storyblok.com/f/152150/1800x1345/3c9757e5ed/about-time-1.png',
        imageAlt: 'User picking up tracker from FightCamp Console dock with 4 trackers',
        product: {
          id: trackers?.variants[0]?.id,
          price: trackers?.variants[0]?.price,
          category: 'accessory',
        },
        itemId: trackers?.variants[0]?.id,
      },
    ] : [],
    warranty: {
      name: `Extended Warranty - $${warrantyItem?.variants[0]?.price / 100}`,
      description: 'Yes, upgrade to lengthen your coverage to 36 months. (Highly Recommended)',
      image: product.fightcampTrackersBagExtWarranty79.img_url,
      imageAlt: 'FightCamp trackers and extended warranty shield icon with check mark at center',
      product: {
        id: warrantyItem ? warrantyItem?.variants[0]?.id : null,
        price: warrantyItem ? warrantyItem?.variants[0]?.price : null,
        category: 'warranty',
      },
      itemId: warrantyItem ? warrantyItem?.variants[0]?.id : null,
    },
    bonusOptions: [],
    selects: [{
      name: 'Quick Wraps Size',
      key: 'pair1',
      htc: 'How To Choose the Size',
      hint: 'Choose your size',
      value: pkg?.variants[0].uid,
      options: pkg?.variants.map((v) => ({
        key: v.uid,
        name: v.name,
      })),
    }],
    variants: pkg?.variants.map((v) => ({
      keys: {
        pair1: v.uid,
      },
      sku: v.id,
    })),
  };
};
