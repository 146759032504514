import product from '@/shared/datasets/products';

export const shopifyConsoleWorksData = ({
  pkg,
  upsellItems = null,
}) => {
  /*
    Order matters in Storyblok for the upsell Items since we are indexing the array
    Shopify variant names matter as well since we are using them to find the correct variant here
  */
  const lightGloves = upsellItems && upsellItems[0] && upsellItems[0].variants
    ? upsellItems[0].variants.find((v) => v?.name?.toLowerCase()?.includes('12oz'))
    : null;
  const heavyGloves = upsellItems && upsellItems[0] && upsellItems[0].variants
    ? upsellItems[0].variants.find((v) => v?.name?.toLowerCase()?.includes('16oz'))
    : null;
  const kidGloves = upsellItems && upsellItems[1] && upsellItems[1].variants
    ? upsellItems[1].variants[0]
    : null;

  const description = 'Get all the essentials for the ultimate FightCamp experience, including premium accessories and an extra pair of Trackers to workout with a partner. Track your progress, compete against others on the app or in your home, and access our complete library of on-demand workouts.';

  return {
    title: pkg?.title,
    id: pkg?.id,
    text: 'Our essentials package',
    description: pkg?.description ? pkg?.description : description,
    product: {
      price: pkg?.variants[0]?.price,
      affirmPrice: pkg?.variants[0]?.price,
      oldPrice: pkg?.variants[0]?.compareAtPrice,
      months: 24,
      monthly: Math.floor(pkg?.variants[0]?.price / 24),
      path: product.fightcampConsoleTheWorks.path,
      internalPath: product.fightcampConsoleTheWorks.internalPath,
    },
    category: 'package',
    coverImage: '/v4/fc-fightcamp-base-002.png',
    coverImageAlt: 'Fightcamp quick wraps and trackers',
    secondaryImage: '/v4/fc-fightcamp-base-man-shadowboxing-001.jpeg',
    secondaryImageAlt: 'Man with fightcamp base shadowboxing',
    includes: {
      basePackage: [
        {
          name: 'Console + 4 Trackers',
          image: {
            src: 'https://a.storyblok.com/f/152150/181x164/14b91c15d8/small-console-4-trackers.jpg',
            alt: 'FightCamp Console with 4 trackers',
          },
        },
        {
          name: '2 pairs of Quick Wraps',
          image: {
            src: 'v4/fc-product-includes-wraps-001.png',
            alt: 'FightCamp Quick Wraps',
          },
        },
        {
          name: 'Boxing Bag + Bag Ring',
          image: {
            src: 'https://a.storyblok.com/f/152150/247x246/f60105a1a6/small-bag-ring.jpg',
            alt: 'FightCamp Bag and Bag Ring',
          },
        },
      ],
      extras: [
        {
          name: 'Premium Flooring',
          image: {
            src: 'https://a.storyblok.com/f/152150/180x164/0a4023f783/small-heavy-mat.jpg',
            alt: 'FightCamp premium flooring piece',
          },
        },
        {
          name: 'Premium Exercise Mat',
          image: {
            src: 'https://a.storyblok.com/f/152150/165x164/4d7cd76946/small-exercise-mat.jpg',
            alt: 'FightCamp Exercise Mat',
          },
        },
        {
          name: 'Resistance Bands',
          image: {
            src: 'https://a.storyblok.com/f/152150/165x164/36689191e1/small-resistance-bands.jpg',
            alt: 'Set of 3 resistance bands with FightCamp logo',
          },
        },
        {
          name: 'Heart Rate Monitor',
          image: {
            src: 'https://a.storyblok.com/f/152150/201x164/05acf644d7/small-hrm.jpg',
            alt: 'FightCamp Heart Rate monitor with logo',
          },
        },
        {
          name: '36-month Extended Warranty',
          image: {
            src: 'https://a.storyblok.com/f/152150/271x246/93aa811c58/small-36-month-warranty.jpg',
            alt: '36 month warranty text',
          },
        },
      ],
    },
    upsell: upsellItems ? [
      {
        name: upsellItems ? upsellItems[0]?.title : null,
        description: upsellItems ? upsellItems[0]?.description : null,
        image: '/v4/fc-additional-items-gloves-001.png',
        imageAlt: 'FightCamp Boxing Gloves',
        productVariants: [
          {
            name: 'Light Gloves (12 oz)',
            itemId: lightGloves?.id,
            description: 'Best for low-to-mid power strikes.',
            product: {
              id: lightGloves?.id,
              price: lightGloves?.price,
              category: 'accessory',
            },
          },
          {
            name: 'Heavy Gloves (16 oz)',
            itemId: heavyGloves?.id,
            description: 'More padding & weight for heavy hitters.',
            product: {
              id: heavyGloves?.id,
              price: heavyGloves?.price,
              category: 'accessory',
            },
          },
          {
            name: 'Kids Gloves (6 oz)',
            itemId: kidGloves?.id,
            description: 'Get the kids involved.',
            product: {
              id: kidGloves?.id,
              price: kidGloves?.price,
              category: 'accessory',
            },
          },
        ],
      },
    ] : [],
    warranty: {},
    bonusOptions: [],
    selects: [{
      name: 'Quick Wraps Size (Includes 2 Pairs)',
      key: 'pair1',
      htc: 'How To Choose the Size',
      hint: 'Choose your size',
      value: pkg?.variants[0].uid,
      options: pkg?.variants.map((v) => ({
        key: v.uid,
        name: v.name,
      })),
    }],
    variants: pkg?.variants.map((v) => ({
      keys: {
        pair1: v.uid,
      },
      sku: v.id,
    })),
  };
};
